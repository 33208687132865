<template>
  <b-link :to="{ path: '/gpt-archive' }" >
    <feather-icon
      size="21"
      :icon="`ArchiveIcon`"
    />
  </b-link>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  setup() {
    
  },
}
</script>