<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item" v-if="!isDriver && !isGuest">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
     
    </ul>
     <li class="nav-item" v-if="isDriver" style="color:#5f58c4">
        {{ carrier }}
      </li>
    <ul class="nav navbar-nav">
      <li class="nav-item" v-if="$route.path === '/GPT' ||  '/gpt-archive'">
        <router-link to="/gpt" style="text-decoration: none;">
          <div style="display: flex; flex-direction: column; align-items: center;">
            <h3 style="margin-bottom: 3px;">Settlement<strong style="color:#5f58c4">GPT</strong></h3>
            <sub style="align-self: center;">powered by purpletrac</sub>
          </div>
        </router-link>
      </li>
    </ul>


    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <gpt-archive v-if="$route.path === '/GPT' || '/gpt-archive'" class="d-none d-lg-block" />
      <search-bar v-if="role=='Super Admin'"/>
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import GptArchive from './components/GptArchive.vue'
import UserDropdown from './components/UserDropdown.vue'
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    SearchBar,
    DarkToggler,
    GptArchive,
    UserDropdown,
  },
  data() {
    return {
      carrier: getUserData().company_name,
      isDriver: getUserData().driver_id == undefined ? false : true,
      isGuest: getUserData().role == "Guest" ? true : false,
      role: getUserData().role,
    };
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
